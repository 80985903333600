import GTooltip from 'components/GTooltip';
import Styles from './Styles.module.scss';

interface GTextTruncateProps {
    text: string;
    maxLength?: number;
    showTooltip?: boolean;
    className?: string;
    tooltipLength?: number;
    placement?: 'top' | 'bottom' | 'left' | 'right';
}

export default function GTextTruncate({
    text,
    maxLength,
    showTooltip = true,
    className = '',
    tooltipLength,
    placement,
}: GTextTruncateProps): JSX.Element {
    const truncateText = () => {
        if (!maxLength || text.length <= maxLength) {
            return <div className={className}>{text}</div>;
        }

        const truncatedText = text.slice(0, maxLength) + '...';
        const tooltipText = tooltipLength ? text.slice(0, tooltipLength) + '...' : text;

        return showTooltip ? (
            <GTooltip 
                title={tooltipText}
                overlayClassName={Styles.TruncTooltip}
                placement={placement}
            >
                <span className={`${Styles.truncatedText} ${className}`}>
                    {truncatedText}
                </span>
            </GTooltip>
        ) : (
            <span className={`${Styles.truncatedText} ${className}`}>
                {truncatedText}
            </span>
        );
    };

    return truncateText();
}

