import cx from 'classnames';
import { _isEmpty, IsIncludeOrEqual } from 'common/Utils';
import React from 'react';
import DetailsMenu, { IDetailsMenuProps } from '../DetailsMenu';
import TagList from '../TagList';
import Styles from './Styles.module.scss';

export default function CardInfo({
    detailsMenuProps,
    accessStatus,
    extraTags,
    caption,
    description,
    source,
    extraTagField,
    infoTagList,
    tagList,
}: IInfoSectionProps & IImageSectionProps) {
    return (
        <div className={Styles.CardInfoContainer}>
            <div className={Styles.CardInfoHeader}>
                <div style={{ width: '100%' }}>
                    {caption}
                    <div className={Styles.Descripton}>{description}</div>
                </div>

                {(Boolean(accessStatus) || !_isEmpty(detailsMenuProps?.menu)) && (
                    <div
                    id='project-options'
                        className={Styles.CardInfoHeaderMenu}
                    >
                        {Boolean(accessStatus) && (
                            <div
                                className={cx(Styles.Access, 'caption2-bold')}
                                style={{ backgroundColor: accessPalette[accessStatus] }}
                            >
                                {accessStatus}
                            </div>
                        )}

                        {extraTags}

                        {!_isEmpty(detailsMenuProps?.menu) && (
                            <div className={Styles.DetailsMenu}>
                                <DetailsMenu {...detailsMenuProps} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {source} {/*  this result is undefined */}
            <div className={Styles.TagContainer}>
                {extraTagField ? extraTagField : <TagList tagList={infoTagList} />}
                {Boolean(tagList?.length) && (
                    <div className={Styles.Tags}>
                        {tagList
                            ?.filter(tag => tag && !IsIncludeOrEqual(tag, 'N/A'))
                            ?.map((tag, i) => (
                                <div
                                    className={cx(Styles.TagItem, 'caption2-bold truncate')}
                                    style={{ backgroundColor: tagPalette[i] }}
                                    key={i}
                                >
                                    {getValueFromField(tag)}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
}

const accessPalette = { public: '#00ffa7', private: '#503795' };
const tagPalette = ['#24D9D9', '#A4BF00'];

export const getValueFromField = (field: string | number | Array<string>) => (Array.isArray(field) ? field.join(' ') : field);
export interface IInfoSectionProps {
    caption: React.ReactNode;
    description: React.ReactNode;
    accessStatus?: 'public' | 'private';
    extraTags?: Array<React.ReactNode>;
    detailsMenuProps?: IDetailsMenuProps;
    source?: React.ReactNode;
}
export interface IImageSectionProps<T = any> {
    infoTagList?: Dictionary<string | Array<string>>;
    extraTagField?: React.ReactNode;
    tagList?: Array<string | Array<string>>;
}
