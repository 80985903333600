import { IAuthState, IPhoneNumber, ISetupConfigModalState, IUserInfo } from './interfaces';

export function AuthStateFactory(data?: Partial<IAuthState>): IAuthState {
    return {
        isAppInit: data?.isAppInit ?? false,
        loading: data?.loading ?? false,
        token: data?.token ?? null,
        user: UserInfoFactory(data?.user),
        activation_token: data?.activation_token ?? null,
        activationExpired: data?.activationExpired ?? 'valid',
        isActiveMFA: data?.isActiveMFA ?? false,
        setupModal: SetupConfigModalFactory(data?.setupModal),
    };
}

export const UserInfoFactory = (data: Partial<IUserInfo>): IUserInfo => ({
    userId: data?.userId ?? null,
    email: data?.email ?? null,
    displayName: data?.displayName ?? null,
    avatar: data?.avatar ?? null,
    role: data?.role ?? null,
    company: data?.company ?? null,
    country: data?.country ?? null,
    website: data?.website ?? null,
    phoneNumber: PhoneNumberFactory(data?.phoneNumber),
    organization: data?.organization ?? null,
    guideList: data?.guideList ?? [],
});

export function PhoneNumberFactory(data?: Partial<IPhoneNumber>): IPhoneNumber {
    return {
        dialCode: data?.dialCode ?? null,
        number: data?.number ?? null,
    };
}

export function SetupConfigModalFactory(data?: Partial<ISetupConfigModalState>): ISetupConfigModalState {
    return {
        visible: data?.visible ?? false,
        loading: data?.loading ?? false,
    };
}
