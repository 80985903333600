import cs from 'classnames';
import { getMessages } from 'common/helpers';
import GButton from 'components/GButton';
import Styles from './Styles.module.scss';
import { ArrowRight } from 'components/assets/icons';

export function StepButtons({
    current,
    disableNext,
    next,
    prev,
    total,
    hideButtons,
    className,
    showArrow = false,
    extraButton,
    onSubmit,
}: StepButtonProps) {
    if (hideButtons) return null;
    return (
        <div className={cs(Styles.StepButtons, className, "create-previous-options")}>
            {current > 0 && (
                <GButton
                    className="step-prev-button"
                    type="default"
                    onClick={() => prev()}
                    id="prev-step"
                    shape={showArrow ? 'circle' : 'default'}
                >
                    {showArrow ? (
                        <div className={Styles.LeftArrow}>
                            <ArrowRight />
                        </div>
                    ) : (
                        getMessages('000453')
                    )}
                </GButton>
            )}
            {current < total - 1 && (
                <GButton
                    type="primary"
                    onClick={() => next()}
                    id="next-step"
                    className={cs('step-next-button', { disabled: disableNext })}
                    shape={showArrow ? 'circle' : 'default'}
                >
                    {showArrow ? <ArrowRight /> : getMessages('000141')}
                </GButton>
            )}
            {extraButton && current === total - 1 && (
                <GButton
                    type="primary"
                    onClick={() => onSubmit()}
                    id="step-extra"
                    className={cs('step-next-button', { disabled: disableNext })}
                    disabled={disableNext}
                >
                    {getMessages('000843')}
                </GButton>
            )}
        </div>
    );
}

interface StepButtonProps {
    current: number;
    next: () => void;
    prev: () => void;
    total: number;
    disableNext?: boolean;
    hideButtons?: boolean;
    className?: string;
    showArrow?: boolean;
    extraButton?: React.ReactNode | boolean;
    onSubmit?: () => void;
}
