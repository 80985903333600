import { useEffect } from 'react';
import { unstable_useBlocker } from 'react-router-dom';

type BlockerFunction = ({ currentLocation, nextLocation }: { 
    currentLocation: any; 
    nextLocation: any; 
}) => boolean;

export function usePrompt({ message, shouldBlock }: IArgs) {
    let blocker = unstable_useBlocker(shouldBlock);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            let proceed = window.confirm(message);
            if (proceed) {
                setTimeout(blocker.proceed, 0);
            } else {
                blocker.reset();
            }
        }
    }, [blocker, message]);

    useEffect(() => {
        if (blocker.state === 'blocked' && !shouldBlock) {
            blocker.reset();
        }
    }, [blocker, shouldBlock]);
}

interface IArgs {
    message: string;
    shouldBlock: boolean | BlockerFunction;
}
