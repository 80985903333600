import { TableColumnsType } from 'antd';
import { IDataset } from 'common/entities';
import { getMessages } from 'common/helpers';
import { _findBy } from 'common/Utils';
import { useSelector } from 'react-redux';
import { selectDataSourceList } from 'redux/selectors';

export function useDatasetTable(dataSource?: IDataset[]): { columns: TableColumnsType<IDataset> } {
    const sourceList = useSelector(selectDataSourceList);

    const columns: TableColumnsType<IDataset> = [
        {
            dataIndex: 'datasetName',
            title: getMessages('000039'),
            filterSearch: true,
            onFilter: (value: any, record: any) => record.datasetName.includes(value),
            filters: dataSource?.map(item => ({ text: item.datasetName, value: item.datasetName.toString() })) || [],
        },
        {
            dataIndex: 'modality',
            title: getMessages('000021'),
            render: renderBaseField,
            filterSearch: true,
            onFilter: (value: any, record: any) => record.modality.includes(value),
            filters: Array.from(new Set(dataSource?.map(item => item.modality.toString())))?.map(modality => ({
                text: modality,
                value: modality,
            })),
        },
        {
            dataIndex: 'anatomy',
            title: getMessages('000023'),
            render: renderBaseField,
            filterSearch: true,
            onFilter: (value: any, record: any) => record.anatomy.includes(value),
            filters: Array.from(new Set(dataSource?.map(item => item.anatomy.toString())))?.map(anatomy => ({
                text: anatomy,
                value: anatomy,
            })),
        },
        {
            dataIndex: 'encoding',
            title: getMessages('000292'),
            render: renderBaseField,
        },
        {
            dataIndex: 'category',
            title: getMessages('000635'),
            render: renderBaseField,
            filterSearch: true,
            onFilter: (value: any, record: any) => record.category.includes(value),
            filters: Array.from(new Set(dataSource?.map(item => item.category?.toString()).filter(category => category)))?.map(
                category => ({ text: category, value: category })
            ),
        },
        {
            dataIndex: 'sampleSize',
            title: getMessages('000043'),
        },
        {
            dataIndex: 'sourceId',
            title: getMessages('000564'),
            render: (value: any) => _findBy(sourceList, 'source_id', value)?.source_name,
        },
        {
            dataIndex: 'createdDate',
            title: getMessages('000769'),
        },
    ].map(item => ({ ...item, key: item.dataIndex }));

    return { columns };
}

export const renderBaseField = (value: any) => (Array.isArray(value) ? value.join(', ') : value);

export interface IUseDatasetTableArgs {}
