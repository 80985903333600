import getBrowserFingerprint from 'get-browser-fingerprint';
import { v4 as uuid } from 'uuid';

export function getSessionId() {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
        sessionId = uuid();
        sessionStorage.setItem('sessionId', sessionId);
    }

    return sessionId;
}

export function getDeviceId() {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
        deviceId = getBrowserFingerprint({ hardwareOnly: true });
        localStorage.setItem('deviceId', deviceId);
    }

    return deviceId;
}

export function getUserId() {
    const user = sessionStorage.getItem('user');

    if (!user) return null;

    return JSON.parse(user).userId;
}

export function getProjectId() {
    const project_id = localStorage.getItem('activeProject');
    if (project_id && project_id !== 'Select Project') return project_id;
    return undefined;
}
