import { PropPath } from 'common/entities';
import { isNullOrUndefined } from './isNullOrUndefined';

export function _get<T>(obj: T, propPath: PropPath, defaultValue?: any): any {
    type K = keyof T;
    if (!propPath) return obj ?? defaultValue;
    if (isNullOrUndefined(obj)) return defaultValue;
    if (typeof propPath === 'string' || typeof propPath === 'number') {
        propPath = propPath.toString().split('.');
    }

    const [head, ...rest] = propPath;

    if (rest.length) {
        return _get(obj[head as K], rest, defaultValue) ?? defaultValue;
    } else {
        return obj[head as K] ?? defaultValue;
    }
}
