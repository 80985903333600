import { SortingKey } from 'common/entities';
import { getMessages } from 'common/helpers/MLHelper';
import GSelect from 'components/GSelect';
import GTabs, { ITab } from 'components/GTabs';
import DashboardCountMenu from '../DashboardCountMenu';
import Styles from './Style.module.scss';
import ViewTypeSelection from './ViewTypeSelection';

export default function DashboardCaptionPanel({
    onChangeView,
    activeView,
    title,
    onChangeSorting,
    selectedSorting,
    filteredCountMenu,
    tabList,
    onEdit,
}: IDashboardCaptionPanelProps) {
    return (
        <div className={Styles.DashboardCaptionContainer}>
            <div className={Styles.OptionsWrapper}>
                <div className={Styles.LeftSideWrapper}>
                    <DashboardCountMenu countMenu={filteredCountMenu} />
                    <GTabs
                        hideAdd
                        type="editable-card"
                        tabList={tabList}
                        onEdit={onEdit}
                        className={Styles.Filters}
                    />
                </div>
                <div className={Styles.RightSideWrapper}>
                    <div className={Styles.GSelectWrapper}>
                        <div className="pBody-medium white">{getMessages('001517')}</div>
                        <GSelect
                            onChange={onChangeSorting}
                            menuItemList={
                                title !== getMessages('001236') && title !== getMessages('001118')
                                    ? SortOptionList
                                    : SortOptionList.slice(2)
                            } //for project and annotation Page sort option
                            placeholder={getMessages('000671')}
                            value={selectedSorting}
                            allowClear
                            id="popularity-date-sorting-select"
                            size="small"
                        />
                    </div>
                        <ViewTypeSelection
                            onChangeView={onChangeView}
                            activeView={activeView}
                            showTableIcon={title !== getMessages('001236')}
                            className='view-type-selection' 
                        />
                </div>
            </div>
        </div>
    );
}

export interface IDashboardCaptionPanelProps {
    activeView: ViewType;
    onChangeView: (newView: ViewType) => void;
    onChangeSorting?: (newSorting: SortingKey) => void;
    selectedSorting?: SortingKey;
    title: React.ReactNode;
    countMenu?: ICountMenu[];
    filteredCountMenu?: ICountMenu[];
    tabList?: Array<ITab>;
    onEdit?: (targetKey: string, action: 'add' | 'remove') => void;
}

interface ICountMenu {
    title: string;
    value: number;
}

export type ViewType = 'list' | 'card' | 'table' | 'miniCard';

export const SortOptionList = [
    {
        value: 'popularityDesc',
        title: getMessages('000668'),
    },
    {
        value: 'popularityAsc',
        title: getMessages('000667'),
    },
    {
        value: 'lastActivity',
        title: getMessages('001181'),
    },
    {
        value: 'dateLatest',
        title: getMessages('000669'),
    },
    {
        value: 'dateOldest',
        title: getMessages('000670'),
    },
];
