export const annotationDetailSteps = [
    {
        target: '#annotation-detail-menu-dropdown',
        content: 'Access additional project actions such as editing or deleting the project',
        disableBeacon: true,
    },

    {
        target: '#annotation-open-project-button',
        content: 'Start or resume work on the annotation project',
    },
    {
        target: '#annotation-details-annotation-export-btn',
        content: 'Export project annotations in multiple formats',
    },
    {
        target: '#annotation-complete-project-btn',
        content: 'Mark the project as completed once tasks are reviewed and finalized',
    },
    {
        target: '#annotation-highlight-tags',
        content: 'View or link to the current dataset being used for annotation',
    },

    {
        target: "[data-node-key='summary']",
        content: 'View key project details, task instructions, progress, and labeling configurations',
    },
    {
        target: "[data-node-key='team']",
        content: 'Manage project roles and assignments for annotators, reviewers, and managers etc',
    },

    {
        target: "[data-node-key='configuration']",
        content: 'Set up annotation targets, measurement parameters, and project-specific settings',
    },
    {
        target: "[data-node-key='study']",
        content: 'Monitor the status of studies, including user assignments, completion progress, and label counts',
    },
    {
        target: "[data-node-key='analytics']",
        content: 'Monitor progress, completion rates, and user activity through detailed statistics and logs',
    },
    {
        target: "[data-node-key='pre-annotation']",
        content: 'Configure and manage model-based predictions to assist and accelerate the annotation process',
    },
    {
        target: "[data-node-key='annotation-agreement']",
        content: 'Analyze annotation consistency and quality metrics, including inter-rater reliability',
    },
    {
        target: "[data-node-key='guideline']",
        content: 'You can add text, images and links to the guideline.',
    },
    {
        target: "[data-node-key='backup']",
        content: 'View a list of project backups with details such as backup ID, date, size, user, and associated study',
    },
];
export const projectDashboardSteps = [
    {
        target:'#filter-panel-searchbox',
        content: 'Use the search bar at the top to quickly find specific projects by name or keyword.',
        disableBeacon: true,
    },
    {
        target:"[data-node-key='project-dashboard']",
        content:'View all projects in the "Projects" tab, where you can access, manage, activate and organize ongoing work.',
    },
    {
        target:"[data-node-key='project-create']",
        content:'Click on "Create New Project" to start a new project. You can define its details, set configurations.',
    },
    {
        target:"[data-node-key='project-archived']",
        content:'Access past or archived projects in the "Archived Projects" tab for reference',
    },
    {
        target:"#popularity-date-sorting-select",
        content:'Use the "Sort By" dropdown to arrange projects based on criteria like last viewed or creation date order.',
    },
    {
        target:".view-type-selection",
        content:'Switch between card and list views to customize how projects are displayed for better accessibility and organization.',
    },
    {
        target:'.dashboard-card-container',
        content:' Each project card displays some information like the project title, description, and tags etc. Click on a project to open it and start working.',
    },
    {
       target:"#project-options",
        content:'Click on the three-dot menu on a project card to access additional actions, such as activating, archiving, or deleting the project.',
    },
    {
        target:".joyride-breadcrumb",
        content:' Click on the breadcrumb navigation ("Home > Project") to return to the main dashboard at any time.',
    },
];
export const projectDetailSteps =[
    {
        target:".joyride-breadcrumb",
        content:'Click the back arrow or "Home > Project" breadcrumb to return to the main project dashboard',
        disableBeacon: true,
    },
    {
        target:".info-wrapper" ,
        content:'This section provides a summary of the project, including the number of models, datasets, annotations, and validations associated with it.'
    },
    {
        target:"#project-detail-menu-dropdown",
        content:'Click the three-dot menu to access project management options such as delete or archive project.',
    },
    {
        target:".active-btn",
        content:'Click "Activate Project" to enable this project for active use.',
    },
    {
        target:"[data-node-key='overview']",
        content:'View a high-level summary of project details, including creation date, available datasets, models, annotations, and validations.',
    },
    {
        target:"[data-node-key='models']",
        content:'Manage and view all AI models linked to this project. Each row contains model details such as modality, problem type, and status.',
    },
    {
        target:"[data-node-key='datasets']",
        content:'Access and organize datasets associated with the project. View dataset details, including modality, format, category, and size.',
    },
    {
        target:"[data-node-key='validations']",
        content:'Monitor validation progress and results for different models. Each validation entry includes status, assigned users, and key performance metrics.',
    },
    {
        target:"[data-node-key='annotations']",
        content:'View and manage project annotations. Check status, creation date, and detailed descriptions for each annotation task.',
    },
    {
        target:"[data-node-key='accessManagement']",
        content:'Control project permissions by adding or removing users. Set access levels to manage team collaboration efficiently.',
    },
    {
        target:".ant-table-thead",
        content:'Use filters and sorting options in each tab to quickly find models, datasets, annotations, or validation results.',
    },
    {
        target:".add-button",
        content:'Use the “Add” buttons at the bottom of relevant tabs to add new models, datasets, annotations, or validations to your project.',
    },
];

export const projectCreateSteps = [
    {
        target: '#project-create-name',
        content: 'Enter a descriptive name for your project to easily identify it.',
        disableBeacon: true,
    },
    {
        target: '#project-create-description',
        content: "Provide a brief summary of the project's purpose (Max: 200 characters).",
    },
    {
        target: '.joyride-step-option',
        content: 'Choose between Public (accessible to all) or Grant User Permission (restrict access to selected users).',
    },
];

export const ModelSelection = [
    {
        target: '.filter-panel-section',
        content:'Locate models using the search bar and apply filters such as modality, anatomy, problem type, deployment status, or source.',
        disableBeacon: true,
    },
    {
        target: '.select-all',
        content: 'Click on model card/cards you want to add to your project. Multiple selections are allowed.',
    },
    {
        target: '.joyride-step-model-view',
        content: 'Choose between Card, Mini Card, List, or Table View to customize the display.',
    },
];
export const DatasetSelection = [
    {
        target: '.filter-panel-section',
        content: 'Locate datasets using the search bar and apply filters such as modality, anatomy, image format, and category.',
        disableBeacon: true,
    },
    {
        target: '.select-all',
        content: ' Click on dataset card/cards you want to add to your project. Multiple selections are allowed.',
    },
    {
        target: '.joyride-step-model-view',
        content: 'Choose between Card, Mini Card, List, or Table View to customize the display.',
    },
];

export const AnnotationSelection = [
    {
        target: '.filter-panel-section',
        content: 'Use search and filters to find annotations based on project status (e.g., completed, in progress).',
        disableBeacon: true,
    },
    {
        target: '.select-all',
        content: 'Click on annotation cards to add them to your project.',
    },
    {
        target: '.joyride-step-model-view',
        content: 'Choose between Card, Mini Card, List, or Table View for better navigation.',
    },
];

export const Overview = [
    {
        target:'.dataset-table',
        content:"Review the selected datasets to confirm they meet your project requirements. If you need to make changes, click 'Previous' until you are navigated to the 'Dataset Selection' page and update your selections.",
        disableBeacon:true,
    },
    {
        target:'.model-table',
        content:"Review the selected models to confirm they meet your project requirements. If you need to make changes, click 'Previous' until you are navigated to the 'Model Selection' page and update your selections.",
    },
    {
        target:'.annotation-table',
        content:"Review the selected annotations to confirm they meet your project requirements. If you need to make changes, click 'Previous' until you are navigated to the 'Annotation Selection' page and update your selections.",
    },
    {
        target:'.previous-validations',
        content:"If there are validations created with the combination of the selected model and datasets, you can select the ones you want to add to the project.",
    },
    {
        target:'.create-previous-options',
        content:"Click 'Create' to finalize the project or 'Previous' to return and make changes.",
    }
];