import { Breadcrumb, BreadcrumbProps } from 'antd';
import cx from 'classnames';
import Styles from './Styles.module.scss';
import { Link } from 'react-router-dom';

interface IBreadCrumbItem {
    title: string;
    href?: string;
}

interface GBreadcrumbProps extends BreadcrumbProps {
    items: Array<IBreadCrumbItem>;
}

export default function GBreadcrumb({ items, className, ...props }: GBreadcrumbProps) {
    return (
        <Breadcrumb
            separator=">"
            className={cx(Styles.Breadcrumb, className,"joyride-breadcrumb")}
            {...props}
            
        >
            {items.map((item, index) => (
                <Breadcrumb.Item key={index}
                >{item.href ? <Link to={item.href}>{item.title}</Link> : item.title}</Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
}
