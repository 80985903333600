import { getMessages } from 'common';
import { GSelect } from 'components';
import { useWindowSize } from 'react-use';
import Styles from './Styles.module.scss';
import { useProjectSelection } from './useProjectSelection';

export default function ProjectSelection() {
    const { menuItemList, onChange, activeProjectId, renderProjectOption, renderProjectDropdown, isProjectSelectionRestricted } =
        useProjectSelection();
    const { width } = useWindowSize();
    return (
        <>
            {width > 920 ? (
                <div className={Styles.SelectWrapper}>
                    <GSelect
                        onChange={onChange}
                        menuItemList={menuItemList}
                        menuItemRender={renderProjectOption}
                        dropdownRender={renderProjectDropdown}
                        placeholder={getMessages('001252')}
                        value={activeProjectId}
                        id="project-select"
                        allowClear={!isProjectSelectionRestricted}
                        placement="bottomRight"
                        popupClassName={Styles.ProjectSelectBox}
                    />
                </div>
            ) : null}
        </>
    );
}
