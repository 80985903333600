import { APISlice } from 'app/store/APISlice';
import { ServiceEnum, _isEmpty } from 'common';
import { ValidationResult } from '../entities/';
import {
    IExternalValidation,
    IExternalValidationResponse,
    IExternalValidationTrackingResponse,
} from '../views/externalValidation/entities';
import { IValidationRun } from '../views/summary/entities/IValidation';
import { ImageValidationResult } from '../views/validationAnalysis/components/metrics/components/ImagePreviewModal/getImageProperties';

export const ValidationAPISlice = APISlice.injectEndpoints({
    endpoints: builder => ({
        getIntersectionValidationList: builder.query<IValidationRun[], IIntersectionValidationQueryParams>({
            query: ({ modelList, datasetList }) => ({
                url: `${ServiceEnum.Api}/Validation/GetIntersectionValidationList`,
                method: 'POST',
                body: {
                    modelList,
                    datasetList,
                },
            }),
        }),
        getValidationList: builder.query<Array<IValidationRun>, IValidationListQueryParams>({
            query: params => ({ url: `${ServiceEnum.Api}/Validation/GetValidationList`, params }),
            providesTags: (result, error, params) =>
                params?.projectId ? [{ type: 'ValidationList', id: params.projectId }, 'ValidationList'] : ['ValidationList'],
        }),

        getValidation: builder.query<IValidationRun, string>({
            query: validationId => ({ url: `${ServiceEnum.Api}/Validation/GetValidationList`, params: { validationId } }),
            providesTags: (result, error, params) => ['ValidationList'],
        }),

        restartValidationRun: builder.mutation<Array<IValidationRun>, string>({
            query: batch_job_id => ({
                url: `${ServiceEnum.Validation}/generate`,
                method: 'POST',
                body: {
                    batch_job_id,
                },
            }),
        }),

        deleteValidationRun: builder.mutation<Array<IValidationRun>, string>({
            query: batch_job_id => ({
                url: `${ServiceEnum.Api}/Validation/ValidationSummary/DeleteValidationRun`,
                method: 'POST',
                body: {
                    batch_job_id,
                },
            }),
            invalidatesTags: ['ValidationList'],
        }),

        getExternalValidation: builder.query<IExternalValidationResponse, string>({
            query: validation_id => ({
                url: `${ServiceEnum.Api}/Validation/GetExternalValidation`,
                params: { validation_id },
            }),
        }),

        unArchiveValidationRun: builder.mutation<Array<IValidationRun>, string>({
            query: batch_job_id => ({
                url: `${ServiceEnum.Api}/Validation/UnArchiveValidation`,
                method: 'POST',
                body: {
                    batch_job_id,
                },
            }),
        }),

        archiveValidationRun: builder.mutation<Array<IValidationRun>, string>({
            query: batch_job_id => ({
                url: `${ServiceEnum.Api}/Validation/ArchiveValidation`,
                method: 'POST',
                body: {
                    batch_job_id,
                },
            }),
        }),

        getValidationResult: builder.query<Array<ValidationResult>, IValidationResultQueryParams>({
            query: params => ({
                url: `${ServiceEnum.Api}/Validation/GetValidationResult`,
                params,
            }),
        }),

        getStudyValidations: builder.query<Array<IValidationRun>, IValidationListQueryParams>({
            query: params => ({ url: `${ServiceEnum.Api}/Validation/GetStudyValidationList`, params }),
        }),

        getStudyResultDoc: builder.query<Blob | undefined, { study_job_id: string; study_id: string }>({
            queryFn({ study_id, study_job_id }, api, extraOptions, baseQuery) {
                if (_isEmpty(study_id) || _isEmpty(study_job_id))
                    return { error: new Error('study_id or study_job_id is empty') };

                return baseQuery({
                    url: `${ServiceEnum.Prediction}/study/result`,
                    params: { study_id, study_job_id },
                    responseHandler: response => response.blob(),
                }) as any;
            },
        }),

        getExternalValidationList: builder.query<Array<IExternalValidationTrackingResponse>, IValidationListQueryParams>({
            query: params => ({ url: `${ServiceEnum.Api}/Validation/GetExternalValidationList`, params }),
        }),

        compareReader: builder.mutation<Partial<IExternalValidation>, any>({
            query: payload => ({
                url: `${ServiceEnum.Validation}/emm/metric`,
                method: 'POST',
                body: payload,
            }),
        }),

        getReportList: builder.query<Array<any>, undefined>({
            query: () => ({
                url: `${ServiceEnum.Api}/Reports/GetReportList`,
            }),
        }),

        addNewReport: builder.mutation<Partial<any>, any>({
            query: payload => ({
                url: `${ServiceEnum.Api}/Reports/AddNewReport`,
                method: 'POST',
                body: payload,
            }),
        }),
        getImageValidationResult: builder.query<ImageValidationResult, { image_id: string; batch_job_id: string }>({
            query: ({ image_id, batch_job_id }) => ({
                url: `${ServiceEnum.Api}/Validation/GetImageValidationResults`,
                params: { image_id, batch_job_id },
            }),
        }),
        uploadPdfReport: builder.mutation<void, { file: Blob; metadata: IMetadata }>({
            query: ({ file, metadata }) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("metadata", JSON.stringify(metadata));

                return {
                    url: `${ServiceEnum.Api}/Validation/UploadPdf`,
                    method: "POST",
                    body: formData,
                };
            },
        }),
    }),
});

export const {
    useGetIntersectionValidationListQuery,
    useGetValidationListQuery,
    useRestartValidationRunMutation,
    useDeleteValidationRunMutation,
    useGetExternalValidationQuery,
    useUnArchiveValidationRunMutation,
    useArchiveValidationRunMutation,
    useGetValidationQuery,
    useGetValidationResultQuery,
    useGetStudyValidationsQuery,
    useGetStudyResultDocQuery,
    useGetExternalValidationListQuery,
    useCompareReaderMutation,
    useAddNewReportMutation,
    useGetReportListQuery,
    useGetImageValidationResultQuery,
    useUploadPdfReportMutation,
} = ValidationAPISlice;

interface IIntersectionValidationQueryParams {
    modelList?: string[];
    datasetList?: string[];
}

interface IValidationListQueryParams {
    projectId?: string;
    modelId?: string;
    datasetId?: string;
}

interface IValidationResultQueryParams {
    batch_job_id: string;
    study_id?: string;
    series_id?: string;
    skip?: string;
    limit?: string;
}

interface IMetadata {
    file_type?: string;
    entity_id: string;
    entity_type: string;
    entity_subtype?: string;
    entity_name: string;
}