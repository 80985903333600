import cx from 'classnames';
import { DashboardCard, DashboardList, DashboardMiniCard, TableView } from 'components/assets/icons';
import Styles from './Style.module.scss';

export default function ViewTypeSelection({ onChangeView, activeView, showTableIcon }: IViewTypeSelectionProps) {
    const viewTypeList = showTableIcon
        ? [...viewTypes]
        : [
              {
                  key: 'card',
                  icon: <DashboardCard />,
              },
              {
                  key: 'list',
                  icon: <DashboardList />,
              },
          ];
    return (
        <div className={Styles.IconWrapper}>
            {viewTypeList.map(({ key, icon }) => {
                return (
                    <div
                        className={cx(Styles.Icon, { [Styles.Active]: activeView === key })}
                        onClick={() => onChangeView(key as ViewType)}
                        id={`${key}-view-type`}
                        key={key}
                    >
                        {icon}
                    </div>
                );
            })}
        </div>
    );
}

interface IViewTypeSelectionProps {
    onChangeView: (newView: ViewType) => void;
    activeView: ViewType;
    showTableIcon?: boolean;
    className?:string;
}
export const viewTypes: Array<IViewType> = [
    {
        key: 'card',
        icon: <DashboardCard />,
    },
    {
        key: 'miniCard',
        icon: <DashboardMiniCard />,
    },
    {
        key: 'list',
        icon: <DashboardList />,
    },
    {
        key: 'table',
        icon: <TableView />,
    },
];

interface IViewType {
    key: ViewType;
    icon: JSX.Element;
}

export type ViewType = 'list' | 'card' | 'miniCard' | 'table';
