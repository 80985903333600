import { TableColumnsType } from 'antd';
import { getMessages } from 'common/helpers';
import { _findBy } from 'common/Utils';
import { IModel } from 'pages/Models/entities';
import { useSelector } from 'react-redux';
import { selectVendorList } from 'redux/selectors';

export function useModelTable(modelList?: IModel[]) {
    const sourceList = useSelector(selectVendorList);
    const columns: TableColumnsType<IModel> = [
        {
            dataIndex: 'modelName',
            title: getMessages('000480'),
            filterSearch: true,
            onFilter: (value: any, record: any) => record?.modelName?.includes(value),
            filters: modelList?.map(item => ({ text: item?.modelName, value: item?.modelName })) || [],
        },
        {
            dataIndex: 'modality',
            title: getMessages('000021'),
            render: renderBaseField,
            filterSearch: true,
            onFilter: (value: any, record: any) => record?.modality?.includes(value),
            filters: Array.from(new Set(modelList?.map(item => item.modality).filter(modality => modality))).map(modality => ({
                text: modality,
                value: modality,
            })),
        },
        {
            dataIndex: 'problemType',
            title: getMessages('000556'),
        },
        {
            dataIndex: 'anatomy',
            title: getMessages('000023'),
            render: renderBaseField,
            filterSearch: true,
            onFilter: (value: any, record: any) => record?.anatomy?.includes(value),
            filters: Array.from(new Set(modelList?.map(item => item.anatomy).filter(anatomy => anatomy))).map(anatomy => ({
                text: anatomy,
                value: anatomy,
            })),
        },
        {
            dataIndex: 'vendorId',
            title: getMessages('000564'),
            render: (value: any) => _findBy(sourceList, 'vendor_id', value)?.vendor_name,
        },
        {
            dataIndex: 'createdDate',
            title: getMessages('000769'),
        },
    ].map(item => ({ ...item, key: (item as any).dataIndex }));

    return { columns };
}

export const renderBaseField = (value: any) => (Array.isArray(value) ? value.join(', ') : value);
