import { ColumnType } from 'antd/lib/table';
import cx from "classnames";
import { useModelTable } from 'common/hooks/useModelTable';
import { GDataGrid } from 'components/GDataGrid';
import GSelectionTable from 'components/GDataGrid/GSelectionTable';
import { IGSelectionTableProps } from 'components/GDataGrid/helpers/interface';
import { IModel } from 'pages/Models/entities';
import Styles from "./Styles.module.scss";

export function ModelTable({ selection, className, onChangeSelection, excludeColumns, extraColumns, moveSelectedToTop = true, ...props }: IModelTableProps) {
    let { columns } = useModelTable(props?.dataSource as IModel[]);

    extraColumns?.forEach(col => columns.splice(col.order, 0, col.column));
    excludeColumns?.forEach(c => columns = columns.filter(col => col.key !== c));

    if (selection) {
        return <GSelectionTable
            columns={columns}
            selectionList={selection.selectedModelIds}
            idIndex='modelId'
            onChangeSelection={selection?.onChangeSelection}
            onAllSelected={selection?.onAllSelected}
            selectionType={selection.type}
            allSelected={selection.allSelected}
            selectionElement={selection.selectionElement}
            className={cx(Styles.ModelTable, className)}
            moveSelectedToTop={moveSelectedToTop}
            {...props}
        />;
    }



    return (
        <GDataGrid<IModel>
            columns={columns}
            className={cx(Styles.ModelTable, className)}
            rowKey="modelId"
            idIndex='modelId'
            sorting={{ enabled: true }}
            {...props}
        />
    );
}

export interface IModelTableProps extends Partial<IGSelectionTableProps> {
    selection?: IModelSelection;
    extraColumns?: Array<IModelTableExtraColumn>;
    excludeColumns?: Array<string>;
    moveSelectedToTop?: boolean;
}

export interface IModelTableExtraColumn {
    column: ColumnType<IModel>;
    order?: number;
}

export interface IModelSelection {
    type: "single" | "multiple";
    selectedModelIds?: string[];
    allSelected?: boolean;
    onChangeSelection?: (modelId: string, checked: boolean) => void;
    onAllSelected?: (allSelected: boolean) => void;
    selectionElement?: (modelId?: string) => React.ReactNode;
}