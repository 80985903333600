import { AuthService, getMessages } from 'common';
import { IDetailsMenuProps } from 'common/components/DashboardView/components/DetailsMenu';
import { Notification, showMessageModal } from 'components';
import { IDropdownMenuItem } from 'components/GDropdown';
import { Activate, ArchiveFile, Delete20, Stop } from 'components/assets/icons';
import { IUserInfo } from 'pages/Authentication/entities';
import { updateUserAccountInfo } from 'pages/Authentication/store';
import { getDatasetListTransaction } from 'pages/Datasets/store/actions';
import { getModelListTransaction } from 'pages/Models/store/actions';
import { getValidationListTransaction } from 'pages/Validation/views/summary/store/actions';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCurrentUser } from 'redux/selectors';
import { IProject } from '../entities';
import { useDeleteProjectMutation, useToggleArchiveProjectMutation } from '../store/ProjectService';
import Styles from './Styles.module.scss';
import { useActivateProject } from './useActivateProject';

export function useDetailsMenu(project: IProject): IDetailsMenuProps {
    const user = useSelector(selectCurrentUser);
    let { projectActiveView } = useParams();
    const dispatch = useDispatch();
    const { projectId, isArchived } = project;
    const activeProjectId = localStorage.getItem('activeProject') ?? null;
    const isActiveProject = activeProjectId === project?.projectId;
    const [visible, setVisible] = useState(false);
    const [deleteProject] = useDeleteProjectMutation();
    const [toggleArchiveProject] = useToggleArchiveProjectMutation();

    function handleToggleArchive(action: 'archive' | 'unarchive') {
        const actionMessage = action === 'archive' ? '001805' : '001806';
        const successMessage = action === 'archive' ? '001803' : '001804';

        showMessageModal({
            type: 'info',
            content: getMessages(actionMessage),
            cancelButton: {
                enabled: true,
            },
            okButton: {
                text: getMessages('000397'),
            },
        }).then(() => {
            const response = toggleArchiveProject({ project_id: projectId, action });
            if (response) {
                Notification.success({ content: getMessages(successMessage) });
            }
        });
    }

    async function handleDelete() {
        showMessageModal({
            type: 'info',
            content: getMessages('001249'),
            cancelButton: {
                enabled: true,
            },
            okButton: {
                text: getMessages('000449'),
                color: 'danger',
            },
        }).then(async () => {
            const response = deleteProject(projectId);
            if (isActiveProject) {
                localStorage.removeItem('activeProject');
                const projectOptions = {
                    activeId: '',
                    defaultId: user?.projectOptions.defaultId || '',
                    isStrict: false,
                };
                const res = await AuthService.UpdateUser.post({ projectOptions });
                dispatch(updateUserAccountInfo(res?.data as IUserInfo));
                dispatch(getModelListTransaction());
                dispatch(getDatasetListTransaction());
                dispatch(getValidationListTransaction());
            }
            if (response) {
                Notification.success({ content: getMessages('001247') });
            }
        });
    }

    const handleActivation = useActivateProject({ projectId });

    let menu: Array<IDropdownMenuItem> = [
        {
            key: 'remove',
            render: (
                <div
                    className={Styles.MenuItem}
                    onClick={() => handleDelete()}
                    id="project-delete"
                >
                    <Delete20 style={{ textAlign: 'center', width: '20px' }} />
                    <div className="caption2-normal">{getMessages('001248')} </div>
                </div>
            ),
        },
    ];

    isArchived
        ? menu.push({
              key: 'unarchive',
              render: (
                  <div
                      className={Styles.MenuItem}
                      onClick={() => handleToggleArchive('unarchive')}
                      id="project-unarchive"
                  >
                      <ArchiveFile style={{ textAlign: 'center', width: '20px' }} />
                      <div className="caption2-normal">{getMessages('001808')} </div>
                  </div>
              ),
          })
        : menu.push({
              key: 'archive',
              render: (
                  <div
                      className={Styles.MenuItem}
                      onClick={!isActiveProject ? () => handleToggleArchive('archive') : null}
                      id="project-archive"
                  >
                      {' '}
                      <ArchiveFile style={{ textAlign: 'center', width: '20px' }} />
                      <div className="caption2-normal">{getMessages('001807')} </div>
                  </div>
              ),
              disabled: isActiveProject,
          });

    !projectActiveView &&
        (isActiveProject
            ? menu.unshift({
                  key: 'deactivate',
                  render: (
                      <div
                          className={Styles.MenuItem}
                          onClick={handleActivation}
                          id="project-deactivate"
                      >
                          <Stop style={{ textAlign: 'center', width: '20px' }} />
                          <div className="caption2-normal">{getMessages('001243')} </div>
                      </div>
                  ),
              })
            : menu.unshift({
                  key: 'activate',
                  render: (
                      <div
                          className={Styles.MenuItem}
                          onClick={!isArchived && handleActivation}
                          id="project-activate"
                      >
                          <Activate style={{ textAlign: 'center', width: '20px' }} />
                          <div className="caption2-normal">{getMessages('001235')} </div>
                      </div>
                  ),
                  disabled: isArchived,
              }));

    if (isActiveProject) {
        menu = menu.filter(item => !['remove', 'archive'].includes(item.key));
    }
    if (user?.projectOptions?.isStrict) {
        menu = menu.filter(item => item.key !== 'deactivate');
    }

    return { visible, setVisible, menu };
}
